import Bugsnag, { Event } from '@bugsnag/js';
import { AuthRefreshAbortError } from '@creditornot/wolt-auth';
import * as uuid from 'uuid';

import {
  ErrorReporter,
  LeaveBreadcrumbFunction,
  SendErrorReportFunction,
} from '@creditornot/ops-tools-error-reporting';

export type ErrorInfo = { error: Error; errorId?: string; errorUrl?: string };

const releaseStage = process.env.RELEASE_STAGE;
const enabledReleaseStages = ['development', 'production'];

export function startErrorReporting() {
  const bugsnag = Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: process.env.APP_VERSION,
    releaseStage,
    enabledReleaseStages,
    autoTrackSessions: false,
    onError(event) {
      const errorInfo = errorFilter(event);

      if (errorInfo?.display) {
        errorReporter.displayShellError?.(errorInfo);
      }

      return !!errorInfo;
    },
  });

  const sendErrorReport: SendErrorReportFunction = (
    error,
    { severity = 'error', metadata, fingerprint, unhandled } = {},
  ) => {
    let reportId: string | undefined;
    bugsnag.notify(error, event => {
      event.severity = severity;
      event.groupingHash = fingerprint;

      if (typeof unhandled !== 'undefined') {
        event.unhandled = unhandled;
      }

      reportId = event.getMetadata('DEBUG')?.UUID;

      if (metadata) {
        Object.entries(metadata).forEach(([section, meta]) => {
          event.addMetadata(section, meta);
        });
      }
    });

    return reportId
      ? {
          reportURL: getBugsnagEventUrl(reportId),
          reportId,
        }
      : undefined;
  };

  const leaveBreadcrumb: LeaveBreadcrumbFunction = (message, { metadata, type } = {}) => {
    bugsnag.leaveBreadcrumb(message, metadata, type);
  };

  const errorReporter: ErrorReporter & { displayShellError?: (errorInfo: ErrorInfo) => void } = {
    sendErrorReport,
    leaveBreadcrumb,
  };

  return errorReporter;
}

function errorFilter(event: Event): (ErrorInfo & { display: boolean }) | undefined {
  if (event.originalError instanceof AuthRefreshAbortError || isNetworkError(event.originalError)) {
    return;
  }

  const releaseInfo = getAppRelease(event.originalError);
  const chunkURL = getScriptURLFromError(event.originalError);

  const appVersionString = releaseInfo && releaseInfo.join(':');
  const [app] = releaseInfo;

  if (localStorage.getItem('error-test')) {
    console.log('originalStackTrace', event.originalError?.stack);
    console.log('obtainedChunkURL', chunkURL);
    console.log('appVersionString', appVersionString);
  }

  event.app.version = appVersionString;

  event.addMetadata('APP', 'shellVersion', process.env.APP_VERSION);
  event.addMetadata('DEBUG', 'UUID', createUUID());
  event.addMetadata('DEBUG', 'originalStackTrace', event.originalError?.stack);
  event.addMetadata('DEBUG', 'obtainedChunkURL', chunkURL);
  event.addMetadata('DEBUG', 'app', app);

  return {
    error: event.originalError,
    errorId: event.getMetadata('DEBUG')?.UUID,
    errorUrl: getBugsnagEventUrl(event.getMetadata('DEBUG')?.UUID),
    display: app === 'shell' && event.originalError,
  };
}

/** Returns whether an error is regarded as a network error */
export function isNetworkError(error: unknown): error is Error {
  const isFetchError = error instanceof TypeError && error.message === 'Failed to fetch';
  const isScriptLoadError = error instanceof Error && error.message.includes("Can't load a script");

  return isFetchError || isScriptLoadError;
}

function createUUID() {
  return uuid.v4();
}

/** Returns release info for the app where the error was thrown */
export function getAppRelease(error: unknown): [app: string, release: string] {
  if (!(error instanceof Error) || !error.stack) {
    return ['unknown', 'unknown'];
  }

  const result =
    /(https?:\/\/.+\/apps\/([^/]+)\/(releases\/([^/]+))?.+)(?=:\d+:\d+)/.exec(error.stack) || [];

  const app = result[2] ?? 'unknown';
  let revision = result[4] ?? 'unknown';
  if (app === 'shell') {
    revision = process.env.APP_VERSION;
  }

  return [app, revision];
}

/** Returns the URL of the script the error was thrown from */
function getScriptURLFromError(error: unknown): string | undefined {
  if (!(error instanceof Error) || !error.stack) {
    return undefined;
  }

  return /(https?:\/\/.+)(?=:\d+:\d+)/.exec(error.stack)?.[1];
}

function getBugsnagEventUrl(uuid: string): string {
  return `https://app.bugsnag.com/wolt-enterprises-oy/opstools/errors?pivot_tab=event&filters[metaData.DEBUG.UUID][0][type]=eq&filters[metaData.DEBUG.UUID][0][value]=${uuid}`;
}
