export function saveRedirectBack() {
  const { pathname, search, hash } = window.location;

  const authRedirectBack = getRedirectBack();

  if (!pathname.startsWith('/login') && !pathname.startsWith('/oauth2')) {
    sessionStorage.setItem('authRedirectBack', authRedirectBack || `${pathname}${search}${hash}`);
  }
}

export function getRedirectBack() {
  return sessionStorage.getItem('authRedirectBack');
}

export function clearRedirectBack() {
  return sessionStorage.removeItem('authRedirectBack');
}

export function historyReplaceBack() {
  const redirectBack = getRedirectBack();
  if (redirectBack) {
    history.replaceState({}, '', redirectBack);
  }

  clearRedirectBack();
}
