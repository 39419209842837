import { color } from '@creditornot/cb-ingredients/design-tokens';
import styled from 'styled-components';

const Root = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: ${color.bgOverlay};
  color: ${color.text};
`;

const Container = styled.div`
  padding: 32px;
  border-radius: 8px;
  background-color: ${color.bg};
  box-shadow:
    0 0 12px 0 rgb(0 0 0 / 6%),
    0 12px 12px 0 rgb(0 0 0 / 6%); /* elevation 12 */
  text-align: left;

  max-width: 768px;

  font-feature-settings: 'kern';
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 14px;
  line-height: 20px;
`;

const Header = styled.header`
  margin-bottom: 24px;
`;

const Heading = styled.h2`
  margin: 0;
  color: ${color.textNegative};

  font-feature-settings: 'kern';
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
`;

const Content = styled.section``;

const DescriptionText = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export const OutdatedBrowserScreen = () => {
  return (
    <Root>
      <Container>
        <Header>
          <Heading>Outdated browser</Heading>
        </Header>
        <Content>
          <DescriptionText>
            Your browser version is more than 2 years old. Please update your browser to use
            OpsTools.
          </DescriptionText>
        </Content>
      </Container>
    </Root>
  );
};
