import { CookbookProvider } from '@creditornot/cb-provider';
import { AuthProvider } from '@creditornot/wolt-auth/react';
import { ReactNode, StrictMode, Suspense, lazy, useSyncExternalStore } from 'react';
import { ThemeProvider } from 'styled-components';

import { AppCrashError, ErrorBoundary } from '@creditornot/ops-tools-error-reporting';

import { LoadingScreen } from './LoadingScreen';
import { authenticator } from './auth/authenticator';
import { errorReporter } from './errorReporter';
import type { ErrorInfo } from './errorReporting';
import { themeManager } from './themeManager';

type Props =
  | {
      status: 'authenticating' | 'logged-in' | 'logged-out';
      errorInfo?: undefined;
    }
  | {
      status: 'error';
      errorInfo: ErrorInfo;
    };

const LazyEternal = lazy(() => new Promise(() => {}));

const LazyShell = lazy(() =>
  import(/* webpackChunkName: "shell" */ './internal/shell')
    .catch(async error => {
      throw await inspectChunkLoadError('internal/shell.js', error);
    })
    .then(({ initialize, Shell }) => {
      const manager = initialize();
      return {
        default: () => <Shell manager={manager} />,
      };
    }),
);

const LazyLogin = lazy(() =>
  import(/* webpackChunkName: "login" */ './Login').catch(async error => {
    throw await inspectChunkLoadError('login.js', error);
  }),
);

export const App = (props: Props) => {
  const themeMode = useSyncExternalStore(themeManager.subscribe, themeManager.getState);

  return (
    <StrictMode>
      <ErrorBoundary sendErrorReportFn={errorReporter.sendErrorReport}>
        <CookbookProvider themeMode={themeMode}>
          <ThemeProvider theme={{ cbThemeMode: themeMode }}>
            <AuthProvider authenticator={authenticator}>
              <Children {...props} />
            </AuthProvider>
          </ThemeProvider>
        </CookbookProvider>
      </ErrorBoundary>
    </StrictMode>
  );
};

function Children({ status, errorInfo }: Props) {
  const children: Record<Props['status'], () => ReactNode> = {
    authenticating: () => <LazyEternal />,
    'logged-in': () => <LazyShell />,
    'logged-out': () => <LazyLogin />,
    error: () => !!errorInfo && <AppCrashError {...errorInfo} />,
  };

  return (
    <Suspense fallback={<LoadingScreen show message="Authenticating..." />}>
      {children[status]()}
    </Suspense>
  );
}

async function inspectChunkLoadError(chunk: string, error: Error): Promise<Error> {
  if (error.name !== 'ChunkLoadError') {
    return error;
  }
  try {
    const res = await fetch(`/static/apps/shell/releases/${process.env.APP_VERSION}/${chunk}`, {
      method: 'HEAD',
      credentials: 'include',
    });
    await res.text();
    return new Error(
      `Loading chunk ${chunk} failed but subsequent HEAD request succeeded. Status code: ${res.status}`,
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return new Error(`Loading chunk ${chunk} failed and subsequent HEAD request failed: ${error}`);
  }
}
