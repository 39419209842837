import 'core-js/proposals/relative-indexing-method';
import '@creditornot/cb-ingredients/theme/legacy.css';

import { createRoot } from 'react-dom/client';

import { App } from './App';
import { OutdatedBrowserScreen } from './OutdatedBrowserScreen';
import {
  authStorage,
  authenticator,
  handleOAuth2LoginCallback,
  handleOAuth2LogoutCallback,
  initializeAuthentication,
} from './auth';
import { historyReplaceBack, saveRedirectBack } from './auth';
import { setSessionCookie } from './auth/sessionCookie';
import { browserCompatible } from './browserCompatible';
import { errorReporter } from './errorReporter';

function main() {
  console.group('Shell');
  console.info(`environment: ${process.env.APP_ENV}`);
  console.info(`version: ${process.env.APP_VERSION}`);
  console.groupEnd();

  const container = document.getElementById('root');
  if (container === null) {
    throw new Error('#root element not found');
  }

  const root = createRoot(container);

  if (!browserCompatible()) {
    root.render(<OutdatedBrowserScreen />);
    return;
  }

  errorReporter.displayShellError = errorInfo =>
    root.render(<App status="error" errorInfo={errorInfo} />);

  // This is used to copy auth data from production when running app locally against production services
  if (process.env.NODE_ENV !== 'production') {
    const searchParams = new URLSearchParams(window.location.search);
    const authDataParam = searchParams.get('authData');
    if (authDataParam) {
      authStorage.save(JSON.parse(decodeURIComponent(authDataParam)));
      window.history.replaceState(null, '', window.location.pathname);
    }
  }

  switch (location.pathname) {
    case '/oauth2/login-callback':
      handleOAuth2LoginCallback();
      break;
    case '/oauth2/logout-callback':
      handleOAuth2LogoutCallback();
      break;
    default: {
      authenticator.subscribe('status', status => {
        if (status === 'logged-in') {
          // React to a login in another tab
          historyReplaceBack();
          setSessionCookie()
            .then(() => root.render(<App status="logged-in" />))
            .catch(console.error);
        }
      });

      root.render(<App status="authenticating" />);

      navigator.locks.request('initialize-authentication', async () => {
        initializeAuthentication().then(async status => {
          if (status === 'logged-out' && !location.pathname.startsWith('/login')) {
            saveRedirectBack();
            history.replaceState({}, '', '/login');
          }

          if (status === 'logged-in') {
            historyReplaceBack();
          }

          root.render(<App status={status} />);
        });
      });
    }
  }
}

main();
