import { authenticator } from './authenticator';
import { removeSessionCookie, setSessionCookie } from './sessionCookie';

export const initializeAuthentication = async (): Promise<'logged-in' | 'logged-out'> => {
  if (authenticator.getState().status === 'logged-in') {
    try {
      // If we are logged in, set the session cookie
      await setSessionCookie();
    } catch (error) {
      console.error(error);
      // Remove the session cookie if the current session is invalid
      await removeSessionCookie();
      return 'logged-out';
    }

    // Update the session cookie whenever the access token changes
    authenticator.subscribe('accessTokenData', data => {
      if (data) {
        setSessionCookie();
      } else {
        removeSessionCookie();
      }
    });

    return 'logged-in';
  } else {
    // Remove the session cookie if we are logged out upon page load
    await removeSessionCookie();
    return 'logged-out';
  }
};
