import { colors, typographyCss } from '@creditornot/cb-ingredients';
import { color } from '@creditornot/cb-ingredients/design-tokens';
import { Spinner } from '@creditornot/cb-progress';
import { cssThemedValue } from '@creditornot/cb-sc-utils';
import React, { useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { css } from 'styled-components';

type Props = {
  backgroundClassName?: string;
  backgroundOpacity?: number;
  backgroundStyle?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
  containerStyle?: React.CSSProperties;
  foregroundClassName?: string;
  foregroundOpacity?: number;
  foregroundStyle?: React.CSSProperties;
  message?: string;
  opacity?: number;
  roundCorners?: boolean;
  scrollWrap?: boolean;
  show?: boolean;
  style?: React.CSSProperties;
  zIndex?: number;
};

const ScrollWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: auto;
`;

const transitionConfig = {
  timeout: 120,
  classNames: {
    appear: 'LO_fadeTransition-appear',
    appearActive: 'LO_fadeTransition-appear-active',
    enter: 'LO_fadeTransition-enter',
    enterActive: 'LO_fadeTransition-enter-active',
    exit: 'LO_fadeTransition-exit',
    exitActive: 'LO_fadeTransition-exit-active',
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .LO_fadeTransition-appear {
    opacity: 0.01;
  }
  .LO_fadeTransition-appear.LO_fadeTransition-appear-active {
    opacity: 1;
    transition: opacity 120ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  .LO_fadeTransition-enter {
    opacity: 0.01;
  }
  .LO_fadeTransition-enter.LO_fadeTransition-enter-active {
    opacity: 1;
    transition: opacity 120ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  .LO_fadeTransition-exit {
    opacity: 1;
  }
  .LO_fadeTransition-exit.LO_fadeTransition-exit-active {
    opacity: 0.01;
    transition: opacity 120ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
`;

const Background = styled.div<{ $roundedCorners: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: ${color.bg};

  ${({ $roundedCorners }) =>
    $roundedCorners &&
    css`
      border-radius: 8px;
    `}
`;

const TransitionContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ForegroundContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${typographyCss.Body3()}
  font-weight: 500;
  padding: 16px;
`;

const Foreground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${cssThemedValue({ light: colors.pepper64, dark: colors.salt72 })};
`;

export const LoadingScreen: React.FC<Props> = ({
  backgroundClassName,
  backgroundOpacity,
  backgroundStyle,
  children,
  className,
  containerStyle,
  foregroundClassName,
  foregroundOpacity,
  foregroundStyle,
  message = 'Loading...',
  opacity = 1,
  roundCorners = false,
  scrollWrap = false,
  show,
  style,
  zIndex,
}) => {
  const backgroundContainerRef = useRef(null);
  const foregroundContainerRef = useRef(null);

  return (
    <Root className={className} style={containerStyle}>
      {scrollWrap ? <ScrollWrap>{children}</ScrollWrap> : children}

      <TransitionGroup>
        {!!show && (
          <CSSTransition {...transitionConfig} key="bg" nodeRef={backgroundContainerRef}>
            <TransitionContainer ref={backgroundContainerRef}>
              <Background
                className={backgroundClassName}
                $roundedCorners={roundCorners}
                style={{
                  ...backgroundStyle,
                  opacity: backgroundOpacity !== undefined ? backgroundOpacity : opacity,
                  zIndex,
                }}
              />
            </TransitionContainer>
          </CSSTransition>
        )}

        {!!show && (
          <CSSTransition {...transitionConfig} key="fg" nodeRef={foregroundContainerRef}>
            <TransitionContainer ref={foregroundContainerRef}>
              <ForegroundContainer style={style}>
                <Foreground
                  className={foregroundClassName}
                  style={{
                    opacity: foregroundOpacity,
                    ...foregroundStyle,
                    zIndex,
                  }}
                >
                  <Spinner style={{ marginRight: 8 }} color="grey" size="small" /> {message}
                </Foreground>
              </ForegroundContainer>
            </TransitionContainer>
          </CSSTransition>
        )}
      </TransitionGroup>
    </Root>
  );
};
