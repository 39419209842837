import { telemetryLogout } from '@creditornot/browser-telemetry';
import { maybeLogoutUser as maybeSendConverseLogout } from '@creditornot/converse-auth';

import { errorReporter } from '../errorReporter';

import { authenticator } from './authenticator';
import { saveRedirectBack } from './redirectBack';

export async function handleLogout() {
  const authState = authenticator.getState();
  if (authState.status === 'logged-out' && authState.loginError) {
    window.history.replaceState(null, '', '/login');
    return;
  }

  const authDebug = localStorage.getItem('wolt-auth-debug');

  // notify Converse that host app's session has been terminated
  // this should happen before localStorage cleanups
  // more details & discussion: https://wolt.slack.com/archives/C0135HK7GSH/p1676384315079699
  try {
    const revisionHashShort = process.env.APP_VERSION?.substring(0, 7);
    const revisionDateShort = process.env.APP_TIMESTAMP
      ? new Date(process.env.APP_TIMESTAMP).toISOString().slice(0, 10)
      : undefined;

    const revision = [revisionHashShort, revisionDateShort].filter(Boolean).join(' ') || 'N/A';

    maybeSendConverseLogout({
      env: process.env.APP_ENV,
      host: {
        name: 'OpsTools',
        revision,
      },
    });
  } catch (error) {
    errorReporter.leaveBreadcrumb("An error occurred in Converse's logout method.");

    if (error instanceof Error) {
      errorReporter.sendErrorReport(error);
    }
  }

  localStorage.clear();
  localStorage.setItem('wolt-auth-debug', authDebug ?? '');

  // Note: telemetryLogout is used here just to remove the session cookie that browser-telemetry
  // uses to establish/maintain a session.
  // The login counterpart to this, i.e. telemetryLogin is called in the app's mount function
  // and just sets the user in the browser-telemetry's internal state.
  // We don't call telemetryLogin here because the shell's browser-telemetry would have a
  // different instance of internal state than the app's and there's no way to synchronize them currently.
  telemetryLogout();

  saveRedirectBack();

  if (!location.pathname.startsWith('/login')) {
    window.location.href = '/login';
  }
}
