import { createAuthenticator } from '@creditornot/wolt-auth';

import { errorReporter } from '../errorReporter';

import { authService } from './authService';
import { authStorage } from './authStorage';

export const authenticator = createAuthenticator({
  authService,
  storage: authStorage,
  logger: {
    logAction(action, authState) {
      const santiziedAuthState = {
        ...authState,
        user: authState.user ? authState.user.id : undefined,
      };

      errorReporter.leaveBreadcrumb(`Authenticator action: ${action}`, {
        metadata: {
          state: santiziedAuthState,
        },
      });
    },
    logMethod(method) {
      errorReporter.leaveBreadcrumb(`Authenticator method called: ${method}()`);
    },
  },
});

authenticator.subscribe('accessTokenData', data => {
  const now = new Date();
  const metadata = data
    ? Object.entries(data).reduce<Record<string, string | number>>((result, [key, value]) => {
        result[key] = value ? '***' : value;
        if (key === 'accessTokenExpiresAt') {
          result[key] = new Date(data.accessTokenExpiresAt).toISOString();
          result.expiresIn = Math.floor((data.accessTokenExpiresAt - now.getTime()) / 3000);
        }
        return result;
      }, {})
    : {};

  metadata.now = now.toISOString();
  errorReporter.leaveBreadcrumb('Access token data changed', { metadata: metadata });
});

declare module '@creditornot/wolt-auth/react' {
  interface AuthContext {
    authenticator: typeof authenticator;
  }
}
