import { ThemeMode } from '@creditornot/cb-ingredients';

const LOCAL_STORAGE_KEY = 'theme-mode';

function isThemeMode(value: string): value is ThemeMode {
  return !!value && ['light', 'dark', 'lightHighContrast'].includes(value);
}

export type ThemeManager = {
  getState(): ThemeMode;
  setState(themeMode: ThemeMode): void;
  subscribe(fn: () => void): () => boolean;
};

export function createThemeManager(): ThemeManager {
  const listeners = new Set<() => void>();

  return {
    getState(): ThemeMode {
      const value = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (value === null) {
        localStorage.setItem(LOCAL_STORAGE_KEY, 'light');
        return 'light';
      }

      if (!isThemeMode(value)) {
        console.warn(
          `Invalid value '${value}' for themeMode found in local storage. Defaulting to 'light'.`,
        );

        localStorage.setItem(LOCAL_STORAGE_KEY, 'light');
        return 'light';
      }

      return value;
    },

    setState(themeMode: ThemeMode) {
      localStorage.setItem(LOCAL_STORAGE_KEY, themeMode);
      listeners.forEach(fn => fn());
    },

    subscribe(fn: () => void) {
      listeners.add(fn);
      return () => listeners.delete(fn);
    },
  };
}

export const themeManager = createThemeManager();
