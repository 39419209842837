import { authenticator } from '.';

function handleServiceError(message: string) {
  return async (res: Response) => {
    if (res.status >= 400) {
      return Promise.reject(
        new Error(`${message}\n\tStatus: ${res.status}\n\tResponse:\n\t${await res.text()}`),
      );
    }

    return res;
  };
}

export async function setSessionCookie() {
  if (process.env.NODE_ENV === 'test') {
    // Skip this in tests as fetch isn't mocked in the test environment at the time of writing.
    // TODO: This is not ok, and should be adequately mocked and tested.
    return;
  }

  const call = (accessToken: string) =>
    fetch('/set-session-cookie', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

  return call(await authenticator.getAccessToken())
    .then(res => {
      if (res.status === 401) {
        // Manually refresh the access token in case the backend responds with 401 and re-attempt the call.
        // This can happen if the clock on the user machine has progressed non-uniformly compared to the server clock.
        // In this case, the authenticator may keep the access token past its expiration time as determined by the server clock.
        return authenticator.refreshUser().then(authenticator.getAccessToken).then(call);
      }

      return res;
    })
    .then(handleServiceError('Request to set session cookie failed'))
    .then(res => res.text()); // avoid the misleading "Fetch failed loading" log entry
}

export function removeSessionCookie() {
  return fetch('/remove-session-cookie', { method: 'POST' })
    .then(handleServiceError('Request to remove session cookie failed'))
    .then(res => res.text()); // avoid the misleading "Fetch failed loading" log entry
}
