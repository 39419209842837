import { authenticator } from './authenticator';
import { handleLogout } from './logout';

export async function handleOAuth2LoginCallback() {
  const code = new URLSearchParams(location.search).get('code');
  const codeVerifier = sessionStorage.getItem('codeVerifier');

  if (!code || !codeVerifier) {
    location.href = '/login';
    return;
  }

  await authenticator.login({ code, codeVerifier, grantType: 'authorization_code' });

  sessionStorage.removeItem('codeVerifier');

  location.href = '/';
}

export async function handleOAuth2LogoutCallback() {
  await authenticator.logout();
  await handleLogout();
}
